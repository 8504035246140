import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Navbar, Nav } from 'react-bootstrap';

// Import images
import logo from './logo1.png';
import mainImage from './background-image.png';
import mainImage2 from './background-image2.png'
import screenshot1 from './screenshot1.png';
import screenshot2 from './screenshot2.png';
import screenshot3 from './screenshot3.png';
import screenshot4 from './screenshot4.png';
import screenshot5 from './screenshot5.png';

const App = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});
  const [confirmation, setConfirmation] = useState('');


  const [captchaQuestion, setCaptchaQuestion] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState(0);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const isFormValid = () => {
    return (
      validate() && // Validates the form fields
      captchaAnswer === correctAnswer // Checks the CAPTCHA answer
    );
  };

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
    const num2 = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
    setCaptchaQuestion(`${num1} + ${num2} = ?`);
    setCorrectAnswer(num1 + num2);
  };


  useEffect(() => {
    generateCaptcha();
  }, []);


  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      alert('Form submitted successfully!');
      // Here you can handle the form submission to your email
      setFormData({ name: '', email: '', message: '' });
      setConfirmation('Thank you! We’ll be in touch soon.');
      setCaptchaAnswer(''); // Reset captcha answer after submission
      generateCaptcha(); // Generate a new question
    } else {
      alert('Please solve the CAPTCHA correctly and fill all fields!');
    }
  };


  return (
    <div className="app">
      {/* Header Section with Navbar */}
      <Navbar expand="lg" className="navbar">
        <div className="container d-flex align-items-center justify-content-between">
          <Navbar.Brand href="#hero" className="d-flex align-items-center m-0">
            <img src={logo} alt="Logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="align-items-center">
              <Nav.Link href="#hero">Home</Nav.Link>
              <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#screenshots">Screenshots</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {/* Hero Section */}
      <main id="hero" className="hero">
        <img src={mainImage2} alt="Game" className="hero-image" />
        <div>
          <br></br>
          <br></br>

          <a href="https://apps.apple.com/us/app/colour-falls/id6736598819?itscg=30200&itsct=apps_box_badge&mttnsubad=6736598819" style={{ display: "inline-block" }}>
            <img
              src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1731283200"
              alt="Download on the App Store"
              style={{ width: "200px", height: "66px", verticalAlign: "middle", objectFit: "contain" }}
            />
          </a>


        </div>
      </main>
      <section className="game-description">
        <h2>About the Game</h2>
        <p>
          In <strong>Colour Falls</strong>, coloured balls fall from the top of the screen, and your goal
          is to tap only the balls that match the colour of the scoreboard.
          <br />
          <br />
          The game gets faster over time, testing your reflexes and focus. Tap the wrong colour or let the
          correct ones fall through, and you lose a life.
          <br />
          <br />
          Watch out for <span className="power-up heart">heart power-ups</span> to regain lives and
          <span className="power-up points">points boosts</span> for bonus points! Can you top the
          leaderboard?
        </p>


        <div className="floating-shape shape1"></div>
        <div className="floating-shape shape2"></div>
      </section>


      <hr></hr>

      <section id="features" className="game-features">
        <h2>Game Features</h2>
        <ul className="features-list">
          <li><span className="feature-icon">⚡</span> Fast-paced gameplay that tests your reaction times.</li>
          <li><span className="feature-icon">🎨</span> Colour-matching mechanics that challenge your skills.</li>
          <li><span className="feature-icon">💥</span> Points boost power-ups for temporary color matches.</li>
          <li><span className="feature-icon">❤️</span> Heart power-ups to grant an extra life.</li>
          <li><span className="feature-icon">👥</span> Share your scores and challenge friends!</li>
        </ul>

        <div className="floating-shape shape1"></div>
        <div className="floating-shape shape2"></div>
      </section>

      <hr></hr>
      <section id="screenshots" className="screenshots">
        <h2>Game Screenshots</h2>

        {/* Bootstrap Carousel for mobile */}
        <div className="carousel-container d-block d-md-none">
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {[screenshot1, screenshot2, screenshot3, screenshot4, screenshot5].map((screenshot, index) => (
                <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                  <img src={screenshot} className="d-block w-100" alt={`Screenshot ${index + 1}`} />
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        {/* Grid Layout for larger screens */}
        <div className="gallery-container d-none d-md-block">
          <div className="gallery">
            {[screenshot1, screenshot2, screenshot3, screenshot4, screenshot5].map((screenshot, index) => (
              <div className="gallery-item" key={index}>
                <img src={screenshot} alt={`Screenshot ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </section>


      <hr></hr>

      {/* Contact Form Section */}
      <section id="contact" className="contact">
        <div className="container">
          <h2 className="text-center">Contact Us</h2>

          {/* Temporary Notification for Form Downtime */}
          <div className="alert alert-warning text-center mb-4">
            Our contact form is currently down. We apologise for the inconvenience. Please email us directly at
            <a href="mailto:colourfallsuk@gmail.com"> colourfallsuk@gmail.com</a> if you need assistance.
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name:</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                disabled
              />
              {errors.name && <span className="text-danger">{errors.name}</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                disabled
              />
              {errors.email && <span className="text-danger">{errors.email}</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message:</label>
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                disabled
              />
              {errors.message && <span className="text-danger">{errors.message}</span>}
            </div>
            {/* CAPTCHA Question */}
            <div className="mb-3">
              <label className="form-label">{captchaQuestion}</label>
              <input
                type="number"
                value={captchaAnswer}
                onChange={(e) => setCaptchaAnswer(Number(e.target.value))}
                className="form-control"
                disabled
              />
              {captchaAnswer && captchaAnswer !== correctAnswer && (
                <span className="text-danger">Incorrect answer!</span>
              )}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled>
              Send
            </button>
            {confirmation && <p className="text-success mt-3">{confirmation}</p>}
          </form>
        </div>
      </section>


      {/* Footer Section */}
      <footer>
        <p>&copy; 2024 Colour Falls. All rights reserved.</p>
        <ul className="footer-links">
          <li><a href="termsofservice.html">Terms of Service</a></li>
          <li><a href="privacypolicy.html">Privacy Policy</a></li>
          <li><a href="licenseagreement.html">License Agreement</a></li>
        </ul>
      </footer>

    </div>
  );
};

export default App;
